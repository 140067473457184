// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'id'
  }, {
    prop: 'background_image',
    image: true
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'required_points'
  }, {
    prop: 'created_at'
  }],
  tableDataViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'icon',
    label: '',
    img: true
    // width: '100'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'description',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'description_en',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'short_description',
    label: '',
    // width: '40',
    Object: 'value'
  }, {
    prop: 'short_description_en',
    label: '',
    Object: 'value'
    // width: '40'
  }]
}
